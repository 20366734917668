/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    h3: "h3",
    blockquote: "blockquote",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Welcome to the final segment of our three-part blog series introducing ", React.createElement(_components.a, {
    href: "/"
  }, "Prismatic"), ". In ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-and-why-it-matters/"
  }, "Part 1"), ", we laid out why it matters that integrations are hard, why we at Prismatic care so much, and what we're doing about it. In ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective/"
  }, "Part 2"), ", we addressed what makes integrations so hard for engineering teams. In Part 3, I'll explore what makes integrations hard for the other teams that make up a B2B software company."), "\n", React.createElement(_components.p, null, "As my cofounders mentioned in previous posts, Prismatic grew out of our collective prior experience building a company that provided mission-critical software systems for public safety agencies and growing it into an industry leader."), "\n", React.createElement(_components.p, null, "We built, deployed, and maintained hundreds of integrations along the way, and saw the incredible potential of integrations. For users, they can create better workflows, provide quicker access to critical data, and free up time to focus on what's important. For the company providing integrations, they can increase the value of your product, improve customer satisfaction, serve as competitive differentiators, generate revenue opportunities, even increase customer retention by making your product a \"stickier\" part of customers' technology ecosystems. But for all that potential, we also saw a lot of pains."), "\n", React.createElement(_components.h2, {
    id: "my-vantage-point",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#my-vantage-point",
    "aria-label": "my vantage point permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "My vantage point"), "\n", React.createElement(_components.p, null, "My roles at that company gave me a lot of visibility into how integration pains affect all of the \"non-R&D\" teams in a B2B software company, as well as the organization as a whole."), "\n", React.createElement(_components.h3, {
    id: "firsthand-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#firsthand-experience",
    "aria-label": "firsthand experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Firsthand experience"), "\n", React.createElement(_components.p, null, "My first integration memory from our startup days is handing a high-level specification for one of our early integrations to a developer. He'd already been struggled through a couple integrations, and his wary response – \"Are we sure we need to build this?\" – made it clear that integrations were a different beast to build. I wore lots a variety of customer-facing hats in those days, and I quickly experienced firsthand that integrations were also a different beast to demo, address in proposals, gather requirements for, test, train users on, and support."), "\n", React.createElement(_components.h3, {
    id: "a-broader-perspective",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-broader-perspective",
    "aria-label": "a broader perspective permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A broader perspective"), "\n", React.createElement(_components.p, null, "Fast-forward a few years, and I could be found in a chief of staff role working closely with the executive team during a long period of rapid company growth. Scaling up brought new kinds of challenges each quarter, but integration pains showed up again and again on the list of \"must solve\" company priorities."), "\n", React.createElement(_components.p, null, "It clearly wasn't just us. Mergers and acquisitions brought me into close contact with other software companies facing the same integration challenges, and job candidates I interviewed over the years told stories about their integration struggles in other B2B software verticals."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "From my vantage point, one thing was incredibly clear: integration challenges aren't confined to engineering teams. Integrations are an organization-wide problem that affects just about every department and team."), "\n"), "\n", React.createElement(_components.p, null, "If you haven't yet read our previous post about the integration pains encountered by engineering teams, you ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective/"
  }, "should go do that now"), ". Then, let's look at some of the integration challenges that sales, onboarding, customer service, and executive teams encounter."), "\n", React.createElement(_components.h2, {
    id: "sales-teams",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sales-teams",
    "aria-label": "sales teams permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sales teams"), "\n", React.createElement(_components.p, null, "There's plenty of complexity involved in navigating from lead to close. The last thing a sales team needs is something that makes it harder to understand a prospect's needs, paint a clear picture of how your solution meets them, and drive the sale to close. Unfortunately, integrations throw up roadblocks in all those areas."), "\n", React.createElement(_components.h3, {
    id: "sales-teams-lack-integration-resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sales-teams-lack-integration-resources",
    "aria-label": "sales teams lack integration resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sales teams lack integration resources"), "\n", React.createElement(_components.p, null, "Even when you're selling integrations you already offer, understanding a prospect's integration needs and showing how your solution meets them can be a lot harder than discussing core system needs. The sheer volume of other systems your customers use, and thus need integrations to, is certainly part of the problem."), "\n", React.createElement(_components.p, null, "A lack of resources really exacerbates that. While you might know the capabilities of your core system backwards and forwards, you seldom have access to even a basic integration catalog that would allow you to show prospects which integrations you offer and answer questions like \"Do you integrate with System X?\" on the spot. The same goes for more detailed documentation of integration capabilities that would let you answer questions like \"Can it send Data X to System Y whenever Z happens?\""), "\n", React.createElement(_components.p, null, "Instead you say you'll look into it, reach out to R&D teams or whoever can get you those answers, wait for responses, and then eventually follow up with your prospect. All of this introduces uncertainty about your system's capabilities and significantly slows the sales process."), "\n", React.createElement(_components.p, null, "And ability to demo integrations? This is often just a wish list item, leaving you with awkward gaps in demos where you say things like \"At this point the system would send Data X to System Y and then Z would show up here.\""), "\n", React.createElement(_components.h3, {
    id: "selling-new-integrations-has-its-own-challenges",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#selling-new-integrations-has-its-own-challenges",
    "aria-label": "selling new integrations has its own challenges permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Selling new integrations has its own challenges"), "\n", React.createElement(_components.p, null, "If you don't already offer the integration a prospect needs, there's another set of problems. There's more back and forth with other internal teams. Can we build this? How long would it take? How should we price it?"), "\n", React.createElement(_components.p, null, "Producing a clear statement of work for new integrations to ensure everyone's on the same page is incredibly difficult. Prospects themselves often don't understand what they need beyond \"An Integration with System X.\" It's often too early or too slow to pull third-party vendors into the loop at this stage. You struggle to balance defining integration expectations with keeping an eye toward time to close and ensuring the entire sale isn't derailed."), "\n", React.createElement(_components.p, null, "When a new integration is more challenging to build or slower to deploy than predicted, or it fails to meet functionality expectations, you often end up taking the heat from both the customer and the internal teams who worked on it."), "\n", React.createElement(_components.h2, {
    id: "onboarding-teams",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#onboarding-teams",
    "aria-label": "onboarding teams permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Onboarding teams"), "\n", React.createElement(_components.p, null, "Integrations don't get easier after the sale closes and a customer enters the onboarding or implementation phase."), "\n", React.createElement(_components.h3, {
    id: "integrations-delay-onboarding-timelines",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integrations-delay-onboarding-timelines",
    "aria-label": "integrations delay onboarding timelines permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integrations delay onboarding timelines"), "\n", React.createElement(_components.p, null, "Customers are typically anxious to start using their new systems as soon as possible. Unfortunately, integrations introduce a high degree of unpredictability to onboarding timelines."), "\n", React.createElement(_components.p, null, "Many integrations simply take a long time to build due to all the engineering-related difficulties and inefficiencies we addressed in ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective/"
  }, "our previous post"), ". If you're short on development capacity – often due to a backlog of integrations waiting to be built – or an integration undergoes significant requirements changes and rework, it can take even longer. It's not uncommon for integrations to be the blocking factor that delays when a customer can go live. I've never met a project manager who looked forward to delivering that news."), "\n", React.createElement(_components.p, null, "Sometimes you decide to take a customer's system live without a key integration. This moves the project forward, but the tradeoff is a poor initial experience for end users who are missing important functionality from the overall solution. The new system was supposed to make things easier, or faster, or help them do something better, but here they are doing duplicate entry or turning to a second system to look up critical data, or whatever busywork your system and its integrations were supposed to alleviate in the first place."), "\n", React.createElement(_components.h3, {
    id: "rollouts-dont-go-smoothly",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rollouts-dont-go-smoothly",
    "aria-label": "rollouts dont go smoothly permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rollouts don't go smoothly"), "\n", React.createElement(_components.p, null, "All too often, integration rollouts are \"bumpy\" experiences – and that's putting it very gently. Perhaps, as ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective/"
  }, "our previous post"), " discussed, the other vendor doesn't have a test endpoint, so you Test-In-Production™. There's a pretty high probability you'll find something that's not working as it should. Perhaps your own end of the integration was finished at the last minute, so you find yourself doing things like walking your customer through the completed integration for the first time while you Configure-In-Production and Train-In-Production, only to find it doesn't meet their expectations. All of this can leave customer-facing teams fielding urgent change requests or scrambling to reconfigure or troubleshoot on the spot."), "\n", React.createElement(_components.h2, {
    id: "customer-service-teams",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customer-service-teams",
    "aria-label": "customer service teams permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customer service teams"), "\n", React.createElement(_components.p, null, "When it comes to integrations, customer service or technical support teams are hampered by insufficient documentation and limited access to logs and other tools."), "\n", React.createElement(_components.h3, {
    id: "lacking-documentation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lacking-documentation",
    "aria-label": "lacking documentation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Lacking documentation"), "\n", React.createElement(_components.p, null, "Even when integrations are working properly, you seldom have the documentation you need to provide good support for them. Which integrations do we offer? Which customers have which integrations? And which versions of them? Is there a way I can help Customer X configure Integration Y differently so that Field Z populates as well?"), "\n", React.createElement(_components.h3, {
    id: "insufficient-access-to-tools",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#insufficient-access-to-tools",
    "aria-label": "insufficient access to tools permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Insufficient access to tools"), "\n", React.createElement(_components.p, null, "When something goes wrong with an integration and there's no alerting system to give your internal teams a heads up – which is all too often the case – then customers are the first to discover the problem. You take urgent calls from understandably frustrated customers, usually in the form of \"Our integration is down.\""), "\n", React.createElement(_components.p, null, "Most support folks I've met are pretty darn good at troubleshooting and gathering as much data as possible before involving other teams. But with integrations, it can feel like your hands are tied. You often don't have access to integration logs or other tools that would let you take even the most basic troubleshooting steps like confirming the integration's status, so way too frequently you have to bring in other teams like developers, DevOps, or Sys Admins. These issues can take a fair amount of time to solve, leaving you caught in the middle of a slow feedback loop between customers and engineering teams as they investigate."), "\n", React.createElement(_components.p, null, "Of course, all of this often happens after hours."), "\n", React.createElement(_components.h2, {
    id: "executive-teams",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#executive-teams",
    "aria-label": "executive teams permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Executive teams"), "\n", React.createElement(_components.p, null, "Integration challenges reach executive teams as well."), "\n", React.createElement(_components.p, null, "Given all the integration challenges affecting individual departments in a B2B software organization, it's no surprise they also affect key company-wide metrics and goals like customer satisfaction, customer retention, average customer lifetime value, a competitive product offering, headcount, efficient use of resources, even employee satisfaction and retention."), "\n", React.createElement(_components.p, null, "I've seen leadership teams move various integration-related tasks to different teams to try to solve some of the challenges. What if we got product folks involved pre-sale to ensure clearer integration requirements? What if we tucked a handful of developers into the onboarding team to better align integrations with our onboarding process? What if we split off a chunk of the support team to specialize in integrations? What if we created a cross-functional integration team? This is largely a game of integration hot potato."), "\n", React.createElement(_components.h2, {
    id: "big-impacts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#big-impacts",
    "aria-label": "big impacts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Big impacts"), "\n", React.createElement(_components.p, null, "These challenges make the overall process of providing integrations incredibly inefficient, time-consuming, and difficult to do well."), "\n", React.createElement(_components.p, null, "They can't be successfully addressed by implementing piecemeal process changes, and they shouldn't be shrugged off as a series of annoyances that are just part of the job. They have far-reaching effects that prevent software products from benefiting end users anywhere near as much as they could:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Many integrations that would hugely benefit customers are never built in the first place."), "\n", React.createElement(_components.li, null, "Many integrations that are built end up being less than they should be – less capable, less reliable, less secure, less responsive to evolving needs."), "\n", React.createElement(_components.li, null, "The development capacity spent on integration work significantly detracts from core product innovation."), "\n"), "\n", React.createElement(_components.p, null, "Given all the companies right now building software and integrations to help users in all kinds of jobs, the untapped potential is massive. That's why we started Prismatic – to help B2B software companies realize more of that potential by building better, quicker integrations and regaining development capacity to put toward core product innovation."), "\n", React.createElement(_components.p, null, "Our product is an ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), " that provides a purpose-built environment for building, deploying, and supporting integrations, with a comprehensive toolset to address challenges like those discussed here. We look forward to telling you more about it in future posts!"), "\n", React.createElement(_components.p, null, "I know many of you have integration pain points like these, plus others I didn't get into. Every additional perspective on integration challenges helps us ensure Prismatic addresses what teams struggle with most. Send me your integration stories and pain points at ", React.createElement(_components.a, {
    href: "mailto:feeback@prismatic.io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "feedback@prismatic.io"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
